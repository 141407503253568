<template>
<div :class="'iq-sidebar sidebar-default ' + variant" >

    <div class="iq-sidebar-logo d-flex align-items-end justify-content-between">
         <a href="#" class="header-logo">
            <img src="@/assets/images/Ayuda.png" class="img-fluid rounded-normal light-logo" alt="logo">
            <img src="@/assets/images/logo-dark.png" class="img-fluid rounded-normal d-none sidebar-light-img" alt="logo">
            <span>Asiste</span>
        </a>
        <div class="side-menu-bt-sidebar-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="text-light wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor" @click="miniSidebar">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" @load="miniSidebar"/>
                </svg>
        </div>
    </div>

    <div class="data-scrollbar" data-scroll="1" id="sidebar-scrollbar" >
        <nav class="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" class="side-menu" >

                <li :class="( checkActive('DashboardEventos') ) ? 'sidebar-layout active' : 'sidebar-layout'" v-if="this.$store.getters.usuario.role ==='SuperAdmin'">
                    <router-link :to="{name: 'DashboardEventos'}" class="svg-icon">
                        <i class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="h-6 w-6"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z"/>
                            </svg>
                        </i>
                        <span class="ml-2">Eventos</span>
                    </router-link>
                </li>



              <li :class="( checkActive('Ponencias') ) ? 'sidebar-layout active' : 'sidebar-layout'"  v-if="this.$store.getters.usuario.role ==='SuperAdmin' || this.$store.getters.usuario.role ==='Admin'">
                <router-link :to="{name: 'Ponencias'}" class="svg-icon">
                  <i class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="h-6 w-6"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm5 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm-5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm.79-5.373c.112-.078.26-.17.444-.275L3.524 6c-.122.074-.272.17-.452.287-.18.117-.35.26-.51.428a2.425 2.425 0 0 0-.398.562c-.11.207-.164.438-.164.692 0 .36.072.65.217.873.144.219.385.328.72.328.215 0 .383-.07.504-.211a.697.697 0 0 0 .188-.463c0-.23-.07-.404-.211-.521-.137-.121-.326-.182-.568-.182h-.282c.024-.203.065-.37.123-.498a1.38 1.38 0 0 1 .252-.37 1.94 1.94 0 0 1 .346-.298zm2.167 0c.113-.078.262-.17.445-.275L5.692 6c-.122.074-.272.17-.452.287-.18.117-.35.26-.51.428a2.425 2.425 0 0 0-.398.562c-.11.207-.164.438-.164.692 0 .36.072.65.217.873.144.219.385.328.72.328.215 0 .383-.07.504-.211a.697.697 0 0 0 .188-.463c0-.23-.07-.404-.211-.521-.137-.121-.326-.182-.568-.182h-.282a1.75 1.75 0 0 1 .118-.492c.058-.13.144-.254.257-.375a1.94 1.94 0 0 1 .346-.3z"/>

                    </svg>
                  </i>
                  <span class="ml-2">Ponencias</span>
                </router-link>
              </li>


              <li :class="( checkActive('CheckInGeneral') ) ? 'sidebar-layout active' : 'sidebar-layout'"  v-if="this.$store.getters.usuario.role ==='SuperAdmin' || this.$store.getters.usuario.role ==='Admin' || this.$store.getters.usuario.role ==='Recepcion'  || this.$store.getters.usuario.role ==='CoachRecepcion'">
                <router-link :to="{name: 'CheckInGeneral'}" class="svg-icon">
                  <i class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="h-6 w-6"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5Zm4 1a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5Zm0 5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5ZM4 8a1 1 0 0 0 1 1h6a1 1 0 1 0 0-2H5a1 1 0 0 0-1 1Z"/>
                    </svg>
                  </i>
                  <span class="ml-2">Checkin</span>
                </router-link>
              </li>

              <li :class="( checkActive('Agenda2') ) ? 'sidebar-layout active' : 'sidebar-layout'"  v-if="this.$store.getters.usuario.role ==='SuperAdmin'  ">
                <router-link :to="{name: 'Agenda2'}" class="svg-icon">
                  <i class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="h-6 w-6"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zM10 7a1 1 0 0 0 0 2h5V7h-5zm-4 4a1 1 0 0 0-1-1H1v2h4a1 1 0 0 0 1-1z"/>
                    </svg>
                  </i>
                  <span class="ml-2">Agenda</span>
                </router-link>
              </li>


              <li :class="( checkActive('AgendaRecreo') ) ? 'sidebar-layout active' : 'sidebar-layout'"  v-if="(this.$store.getters.usuario.role ==='Profesores' && this.$store.getters.usuario.estatusAsistencia===1 && this.$store.getters.usuario.datos_validados===1)|| this.$store.getters.usuario.role ==='Admin' || this.$store.getters.usuario.role ==='Coach' || this.$store.getters.usuario.role ==='CoachRecepcion'">
                <router-link :to="{name: 'AgendaRecreo'}" class="svg-icon">
                  <i class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="h-6 w-6"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zM10 7a1 1 0 0 0 0 2h5V7h-5zm-4 4a1 1 0 0 0-1-1H1v2h4a1 1 0 0 0 1-1z"/>
                    </svg>
                  </i>
                  <span class="ml-2">Agenda</span>
                </router-link>
              </li>



              <li :class="( checkActive('AgendaSupervisor') ) ? 'sidebar-layout active' : 'sidebar-layout'"  v-if="this.$store.getters.usuario.role ==='SuperAdmin' || this.$store.getters.usuario.role ==='Admin'
|| this.$store.getters.usuario.role ==='Coach'  || this.$store.getters.usuario.role ==='CoachRecepcion'" >
                <router-link :to="{name: 'AgendaSupervisor'}" class="svg-icon">
                  <i class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="h-6 w-6"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
                    </svg>
                  </i>
                  <span class="ml-2">Inscritos</span>
                </router-link>
              </li>


              <li :class="( checkActive('Escuelas') ) ? 'sidebar-layout active' : 'sidebar-layout'"  v-if="this.$store.getters.usuario.role ==='SuperAdmin' || this.$store.getters.usuario.role ==='Admin'">
                <router-link :to="{name: 'Escuelas'}" class="svg-icon">
                  <i class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="h-6 w-6"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z"/>
                      <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z"/>
                    </svg>
                  </i>
                  <span class="ml-2">Escuelas</span>
                </router-link>
              </li>

              <li :class="( checkActive('Reinscribir') ) ? 'sidebar-layout active' : 'sidebar-layout'"  v-if="this.$store.getters.usuario.role ==='SuperAdmin' || this.$store.getters.usuario.role ==='Admin'  || this.$store.getters.usuario.role ==='Recepcion' || this.$store.getters.usuario.role ==='CoachRecepcion'">
                <router-link :to="{name: 'Reinscribir'}" class="svg-icon">
                  <i class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="h-6 w-6"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z"/>

                    </svg>
                  </i>
                  <span class="ml-2">Cambiar sesión</span>
                </router-link>
              </li>

            </ul>
        </nav>
    </div>


</div>
</template>
<script>
import { mapGetters } from 'vuex'
import {core} from '@/config/pluginInit'
export default {
  name:"SidebarStyle",
  props:{
     items: Array,
     variant: {type: String}
  },
  data () {
    return{
      homeurl:'',
      dashboards:['layout.dashboard1','layout.dashboard2','layout.dashboard3','layout.customer','layout.product','layout.order','layout.calendar'],
      app:['app.chat', 'app.todo'],
      charts:['chart.amchart','chart.apexchart','chart.highchart','chart.morrischart',],
      user:['app.user-profile','app.user-add','app.user-list'],
      ui:['Ui.avatars','Ui.alerts','Ui.badges','Ui.breadcrumbs','Ui.buttons','Ui.button-groups','Ui.boxshadows','Ui.colors','Ui.cards','Ui.carousels','Ui.grids','Ui.helper-classes','Ui.images','Ui.list-groups','Ui.media-objects','Ui.modals','Ui.notifications'
      ,'Ui.paginations','Ui.popovers','Ui.progressbars','Ui.typographys','Ui.tabs','Ui.tooltips','Ui.Embed-videos'],
      auth:['auth.login','auth.register','auth.recover-password','auth.confirm-mail','auth.lock-screen'],
      pricing:['price.pay','price.pay2'],
      icon:['icon.dripicon', 'icon.fontawsome','icon.lineawsome','icon.remixicon',],
      error:['error.404','error.500'],
      formControls:['controls.form-checkbox','controls.form-layout','controls.form-input','controls.form-radio','controls.form-switch','controls.form-textarea','controls.form-validation',],
      formWidget:['widget.form-datepicker','widget.form-file-uploader','widget.form-quill',],
      table:['table.basic-table','table.data-table','table.edit-table',],
      timeline:['time.line','time.line1'],
      extraPages:[ 'price.pay','pages.maintenance','pages.comingsoon','pages.invoices','pages.subscribers','pages.faq','pages.blank-page','pages.icon'],
      pages:[]
    }
  },
  mounted () {
    core.SmoothScrollbar()
    const urlParams = new URLSearchParams(window.location.search);
    const sidebar = urlParams.get('sidebar');
    if (sidebar !== null) {
         this.variant=''
        switch (sidebar) {
            case "0":
                this.variant ='sidebar-dark'
            break;
            case "1":
                this.variant ='sidebar-light'
            break;
            default:
                this.variant =''
                break;
        }
    }
  },
    destroyed () {
        core.SmoothScrollbar()
    },
    computed : {
        ...mapGetters({
            logo:'darklogo',
            sidelogo:'logo',
        })
    },
    methods: {
        checkActive(route) {
            if (this.$route.name == route) {
                return true;
            }
            if (route.includes(this.$route.name)) {
                return true
            }
        },
        miniSidebar(){
            core.triggerSet()
        },
        showCollapse(collapseId) {
            document.getElementById(collapseId).closest('li').classList.add('active')
        },
        hideCollapse() {
            const list = document.querySelector('.iq-sidebar-menu').querySelectorAll('.submenu')
            Array.from(list, elem => {
                if (elem.closest('.active') !== null && elem.closest('.active') !== undefined) {
                    elem.closest('.active').classList.remove('active')
                }
            })
        }
    },
}
</script>

